// Our main CSS
import '../css/app.css'
import '@splidejs/splide/css';
import Splide from '@splidejs/splide';
// layzyload and/or play/pause inline videos when they are in/out of view
const autoplaying_videos = document.querySelectorAll('video[playsinline]')

if (autoplaying_videos && autoplaying_videos.length) {
  import('./modules/inline-videos').then((module) => {
    module.default(autoplaying_videos)
  })
}

const notices = document.querySelectorAll('.js-notices')
if (notices && notices.length) {
  const buttons = document.querySelectorAll('.js-show-more');

  buttons.forEach(function(button) {
      button.addEventListener('click', function() {
          // Find the closest parent element with the class 'js-notices'
          const notice = button.closest('.js-notice');
          if (!notice) return;

          // Toggle the visibility of the '.wysiwyg.hidden' div
          const hiddenContent = notice.querySelector('.js-hidden-content');
          if (hiddenContent) {
              hiddenContent.classList.toggle('hidden');
          }

          // Change the button text
          const buttonText = button.querySelector('span:first-child');
          if (buttonText) {
              buttonText.textContent = buttonText.textContent.trim() === 'Visa hela texten'
                  ? 'Minimera texten'
                  : 'Visa hela texten';
          }

          // Add or remove a class from the SVG paths
          const svgPaths = button.querySelectorAll('svg path');
          svgPaths.forEach(function(path) {
              path.classList.toggle('expanded'); // Replace 'expanded' with your desired class name
          });
      });
  });
}

// Check if .splide exists and initialize on each

/*var elms = document.getElementsByClassName( 'splide' );

for ( var i = 0; i < elms.length; i++ ) {
  new Splide( elms[ i ], { autoplay: true } ).mount();
}*/

// Lots of stuff to shuffle slides, and initialize slider.

function shuffleArray(array) {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
}

var elms = document.getElementsByClassName('splide');

for (var i = 0; i < elms.length; i++) {
  var splideEl = elms[i];

  // Get the list element that contains the slides
  var list = splideEl.querySelector('.splide__list');

  // Get an array of the slide elements
  var slides = Array.from(list.children);

  // Shuffle the slides array
  shuffleArray(slides);

  // Remove existing slides from the DOM
  while (list.firstChild) {
    list.removeChild(list.firstChild);
  }

  // Append the shuffled slides back to the list
  slides.forEach(function (slide) {
    list.appendChild(slide);
  });

  // Initialize Splide after shuffling
  new Splide(splideEl, { autoplay: true }).mount();
}


const hamburgerButtons = document.querySelectorAll('.js-hamburger')
const mobileNavMenu = document.querySelector('.js-mobile-nav-menu')

hamburgerButtons.forEach((button) => {
  button.addEventListener('click', function () {
    this.classList.toggle('is-active')
    mobileNavMenu.classList.toggle('hidden')
    document.body.classList.toggle('overflow-hidden')
  })
})

// Ensure the script runs after the DOM is fully loaded
const copyLinkButton = document.getElementById('copy-link-button')
document.addEventListener('DOMContentLoaded', function() {
  if (copyLinkButton) {
    document.getElementById('copy-link-button').addEventListener('click', function() {
        // Copy the current URL to the clipboard
        navigator.clipboard.writeText(window.location.href).then(function() {
            // Success: Swap the icons
            document.getElementById('copy-icon').style.display = 'none';
            document.getElementById('checkmark-icon').style.display = 'inline';
        }, function(err) {
            // Error handling
            console.error('Could not copy text: ', err);
        });
    });
  }
});



import Macy from 'macy'
window.createMacy = Macy;
var macy =  document.getElementById('masonry');
if (typeof (macy) != 'undefined' && macy != null) {
  var macy = Macy({
    container: '#masonry',
    trueOrder: true,
    waitForImages: true,
    margin: 24,
    columns: 3,
    breakAt: {
      850: 2,
      500: 1
    }
  });


  function timeout() {
    macy.reInit();
    setTimeout(function () {
      // Do Something Here
      // Then recall the parent function to
      // create a recursive loop.
      timeout();
    }, 1000);
  }
  timeout();

}